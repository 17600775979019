// General

a {
  border-radius: 50%;
  margin: auto 4vmin;
}

html {
  background-color: black;
}

.App {
  text-align: center;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
  color: #eeeeee;
}

.App-body {
  background-color: black;
  min-height: 100vh;
  display: flex;
  font-size: calc(10px + 2vmin);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #eeeeee;
}


// Home Page

.header1 {
  position: relative;
  top: 2em;
  font-size: 7vmin;
  font-weight: 200;
  background: transparent;
  border-radius: 10px;
  height: 0.03em;
  width: 50%;
  box-shadow: 0 1.2em 0 0 #eeeeee;
}

.header2 {
  position: relative;
  top: 2em;
  font-size: 7vmin;
  font-weight: 200;
  background: transparent;
  border-radius: 10px;
  height: 0.03em;
  width: 7em;
  box-shadow: 0 calc(12px + 1.2em) 0 0 #eeeeee;
}

.bodyContent {
  border-radius: 2em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bodyBlock {
  border-radius: 0.5em;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.9);
}

.portrait {
  height: auto;
  width: 15em;
  border-radius: 50%;
  margin-top: 10em;
}

.backgroundImg {
  width: 100%;
  height: auto;
  opacity: 0.5;
}


// Projects Page

.projectsHeader {
  font-weight: 200;
  margin-top: 75%;
}

.projectsBlock {
  display: flex;
  border-color: #eeeeee;
  margin: 5%;
}

.projectsDetails {
  padding: 2vmin;
  border-color: #eeeeee;
  border-width: 2px 0 0 0;
  border-style: solid;
}

.projectsDetailsBlock {
  border-color: #eeeeee;
  border-width: 2px;
  border-style: solid;
}

.projectsImage {
  background-size: cover;
  border-color: #eeeeee;
  border-style: solid;
  border-width: 2px;
  background-position: center;
}

.projectsTechIcons {
  font-size: calc(10px + 3vmin);
  padding: 1vmin;
}

.projectsSVGTechIcons {
  height: calc(10px + 3vmin);
  width: calc(10px + 3vmin);
  margin-bottom: 0.5vmin;
  padding: 0 1vmin;
}

.projectsLink {
  position: relative;
  left: 0;
  float: right;
  transition: all 0.4s;
  z-index: 999;

  &:hover {
    z-index: 999;
    font-size: 1.2em;
  }
}

.backButton {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 1em;
  left: 1em;

  & div:hover {
    color: black;
    background-color: #eeeeee;
  }

  & div:hover ~ .backButtonText {
    opacity: 1;
  }
}

.backButtonIcon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eeeeee;
  background-color: black;
  fill: #eeeeee;
  width: 2.5em;
  height: 2.5em;
  border-radius: 2.5em;
  transition: all 0.4s;
}

.backButtonText {
  color: #eeeeee;
  position: relative;
  top: -0.5em;
  left: 1em;
  opacity: 0;
  transition: all 0.4s;
}


// Misc. Icons

.chevron1 {
  position: absolute;
  left: 49%;
  bottom: 5%;
  color: #eeeeee;
  fill: #eeeeee;
}

.chevron2 {
  margin-top: 3em;
  color: #eeeeee;
  fill: #eeeeee;
}


// Social Icons

.icon {
  display: flex;
  flex-direction: row;
  color: #eeeeee;
  fill: #eeeeee;
}

.github {
  transition: all 0.4s;
  color: #eeeeee;
  fill: #eeeeee;

  &:hover {
    color: #6441a5;
    fill: #6441a5;
  }
}

.githubBubble {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 2em;
}

.code {
  cursor: pointer;
  margin-top: 0.5em;
  transition: all 0.4s;
  color: #eeeeee;
  fill: #eeeeee;

  &:hover {
    color: red;
    fill: red;
  }
}

.linkedin:hover {
  background: #0077B5;
  fill: #0077B5;
}

.email:hover {
  background: #e70c6b;
  fill: #e70c6b;
}

.resume:hover {
  background: #fecb2c;
  fill: #fecb2c;
}

.bubble {
  background: #eeeeee;
  color: black;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  transition: all 0.4s;
}


// Tech Icons

.Node\.js {
  color: #44883e;
}

.JavaScript {
  color: #f7df1e;
}

.React\.js {
  color: #00d8ff;
}

.React-Native {
  color: #00d8ff;
}


// 404 Page

.NotFound {
  background-color: #ffedef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
  color: #eeeeee;
}

.NotFoundImage {
  height: 30%;
  flex: 1;
}

img.NotFound {
  flex: 1;
  height: 50%;
  width: auto;
}
